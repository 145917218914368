import {CartItemType, OrderStatusState, OrderType} from "@/enums";
import {RouteParamsRaw} from "vue-router";
import {DateTime, Duration} from "luxon";


export interface Order {
    shopId: number,
    orderId:string,
    shopName: string,
    orderType: OrderType,
    pickupTime?: Date | null,
    totalSum?: number,
    partialSum?: number,
    taxSum?: number,
    taxPercentage?: number,
    customerCode: string | null,
    items: OrderItem[],
    note:string|null,
    created?: Date | null
}

export interface OrderItem {
    code: string,
    quantity: number,
    price?: number,
    choices: OrderChoise[],
    name?: string,
    imageUrl?: string,
    isChoice?: boolean | null
}

export interface OrderChoise {
    level: number,
    articleCode: string,
    choiceCode: string,
    price?: number,
    name?: string,
    imageUrl?: string
}

export interface ApplicationSettings {
    orderTypes: OrderType[],
}
export interface OrderResponse {
    shopId: number,
    shopName: string,
    orderType: OrderType,
    pickupTime: Date | null,
    totalSum: number,
    partialSum: number,
    taxSum: number,
    taxPercentage: number,
    items: OrderItem[]
}


export interface Shop {
    id: number | null;
    name: string;
    location: Location;
    distance: number;
    description: string;
    status: StatusType;
    contactInformation: ContactInformation;
    openingHours: OpeningHour[],
    externalAppUri:null
}

export interface Location {
    longitude: number;
    latitude: number;
}

export interface OpeningHour {
    id: number,
    opens: string,
    closes: string,
    dayOfWeek: string,
    validFrom?: boolean,
    validThrough?: boolean,
    closed: boolean
}

export type StatusType = "online" | "offline" | "paused";

export interface ContactInformation {
    address1: string;
    address2: string;
    city: string;
    zipCode: string;
}

export interface Cart {
    items: CartItem[]
    shopId: number,
    shopName: string,
    orderType: OrderType,
    pickupTime?: Date,
    note?:string|null,
    settings: ApplicationSettings
}

export interface CartItem {
    type: CartItemType,
    article: Article;
    choices: SelectedChoices;
    flatChoices: Choice[];
    categoryName?: string,
    quantity: number;
    id: string
}

export interface CartState {
    visible: boolean,
    showOrderInfo: boolean,
    showAddItemButton: boolean,
    hasSelectedSuggestions: boolean,
    disabled: boolean,
    orderType: OrderType,
    shopId: number | null,
    shopName?: string,
    items: CartItem[],
    editMode: boolean,
    currentOrderChanged: boolean,
    note: string|null,
    settings: ApplicationSettings
}

export interface SuggestedArticleCategory {
    description: string,
    order: number,
    articleIds: string[]
}

export interface Article {
    code: string;
    choices: ChoiceLevel[];
    description: string;
    description2: string;
    imageUrl: string;
    price: number;
    salesUnit: string;
    suggestedArticleIds: string[];
    taxCode: number;
    taxCodeSecondary: number;
    additionalInformation: string;
    availableForSale: boolean;
    suggestedArticles: SuggestedArticleCategory[]
    i18n: Translations
}

export class Translations {
    [property:string]:any;
    tr = (property:string, culture:string): string => {
        const translations = this[property] as Translation[];
        const text = translations.find(t => t.language === culture)?.text;
        
        return text || "";
    };
}

export interface Translation{
    language: string,
    text: string
}

export interface ArticleInfo {
    imageUrl: string;
    name: string;
    code: string;
}

export interface Category {
    articles: string[],
    imageUlr: string,
    maxPrice: number,
    minPrice: number,
    name: string
}

export interface Menu {
    choiceLevels: ChoiceLevel[];
    currency: Currency,
    name: string,
    categories: Category[]
}

export interface Choice {
    order: number;
    article: Article;
    quantity: number;
    unit: string;
    code: string;
    id: number;
    level: number
}

export interface ChoiceLevel {
    level: number;
    name: string;
    isMultipleChoice: boolean;
    choices: Choice[];
    minChoices: number;
    maxChoices: number;
    nameOverride:string|null;
    callToActionOverride:string|null;
    i18n: Translations
}

export interface Currency {
    name: string,
    symbol: string
}

export interface SelectedChoices {
    [key: number]: Choice[];
}

export interface Category {
    name: string;
    imageUrl: string;
    maxPrice: number;
    minPrice: number;
    articles: string[];
}

export interface MinMax {
    min: number,
    max: number
}

export interface ApiStatus {
    done: boolean,
    failed: boolean,
    loading: boolean,
    text?: string
}


export interface CartInject {
    cart: CartItem[] | null,
    addToCart(item: CartItem): any,
    removeFromCart(item: CartItem): any
}


export interface SuggestedArticlesInject {
    suggestedArticleIds: string[] | null,
    setSuggestedArticleIds(articleIds: string[]): any
}

export interface NavigationStoreState {
    text: string,
    visible: boolean,
    transparent: boolean,
    url: string,
    category?: string,
    link?: NavigationStoreLink | undefined,


}//RouteLocationRaw

export interface NavigationStoreLink {
    name: string,
    path?: string,
    params: RouteParamsRaw | undefined
}

export interface Languages { [key: string]: string }

export interface DropdownItem {
    label: string;
    value: string;
}

export interface OrderStoreState {
    details: OrderDetails | null
    //status: string | null,
    //orderStatus: OrderStatus | null,
    paidOrders: PaidOrder[],
    deliveredOrders: PaidOrder[]
}

export interface PaidOrder {
    id: string,
    customerCode: string | null
    expires: Date;
}

export interface OrderDetails {
    orderId: string | null;
    accessToken: string | null;
    pickupTime: Date | null;
    totalAmount: number | null;
    customerCode: string | null
    paymentID: string | null;
    userAgent: string | null;
}

export interface OrderStatus {
    orderId: string
    orderNr: string
    totalAmount: number
    created: string
    shop: Shop
    items: OrderStatusItem[]
    orderType: string
    status: OrderStatusState,
    pickupTime: string
    paymentTransactionId: any
    note:string|any
}

export interface OrderStatusItem {
    amount: number
    description: string
    articleCode: string
    normalPrice: number
    purchasePrice: number
    quantity: number
    choices: any[]
}

export interface ResponseError {
    type: string
    title: string
    status: number,
    detail: string
}

export interface ICoupon {
    id: number,
    created: Date,
    validUntil: Date,
    name: string,
    description: string,
    imageUrl: string,
    usedDate: Date | null
}

export class Coupon implements ICoupon{
    constructor(data:ICoupon) {
        this.id = data.id;
        this.created= new Date(data.created);
        this.validUntil = new Date(data.validUntil);
        this.name = data.name;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.usedDate = data.usedDate ? new Date(data.usedDate) : null;
    }

    id: number;
    created: Date;
    validUntil: Date;
    name: string;
    description: string;
    imageUrl: string;
    usedDate: Date | null;
    
    public get state() : CouponState {
        if(!this.usedDate){
            return CouponState.Unused;
        }

        const expires = new Date(this.usedDate);
        expires.setMinutes(this.usedDate.getMinutes()+10);

        if(expires < new Date()){
            return CouponState.Expired
        }

        return CouponState.Active;
    }
    
    getStateClass() : string {
        switch (this.state) {
            case CouponState.Unused:
                return 'coupon-unused';
            case CouponState.Active:
                return 'coupon-active';
            case CouponState.Expired:
                return 'coupon-expired';
        }         
    }

    getTimeLeftForCoupon():Duration {
        
        const expires = DateTime.fromJSDate(this.usedDate?? new Date()).plus({minutes:10});
        const duration =expires.diff(DateTime.local(), ['minutes', 'seconds']);
        return duration;
        //console.log(eh.toFormat('mm:ss',{ floor: true }));
        
        
        // const used = DateTime.fromJSDate(this.usedDate);
        //
        // return DateTime.local().diff(used, ['minutes', 'seconds']);
        
        //
        // const diff = Math.abs(new Date().setMinutes(new Date().getMinutes()) - this.usedDate.getTime());
        // const diff2 = (1000*60*10)-diff;
        //
        // const d = new Date(Date.UTC(0, 0, 0, 0, 0, 0, diff2)),
        //     // Pull out parts of interest
        //     parts = [
        //         d.getUTCHours(),
        //         d.getUTCMinutes(),
        //         d.getUTCSeconds()
        //     ],
        //     // Zero-pad
        //     formatted = parts.map(s => String(s).padStart(2, '0')).join(':');
        //
        // return d;
    }
    
}
export enum CouponState {
    Unused = 0,
    Active = 1,
    Expired = 2
}