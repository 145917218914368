import { createRouter, createWebHistory,  } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
//import TabsPage from '../views/TabsPage.vue'
import HomePage from '../views/HomePage.vue';
import Offline from "@/views/Offline.vue";
//import { ShopStore } from '@/stores/shop';

//const shop = ShopStore();
//import { Component } from 'ionicons/dist/types/stencil-public-runtime';

const routes: Array<RouteRecordRaw> = [
    {
        name: 'home',
        path: '',     
        component: HomePage,
    },
    {
        name: 'offline',
        path: '/offline',
        component: Offline,
    },
    {
        name: 'coupons',
        path: '/coupons',
        component: ()=>import('@/views/Coupons.vue'),
    },
    {
        name: 'shopPage',
        path: '/shop/:shopId',
        props: true,
        component: () => import('@/views/ShopPage.vue')
    },
    {
        name: 'categoriesPage',
        path: '/menu/:shopId',
        props: true,       
        component: () => import('@/views/CategoriesPage.vue')
    },
    {
        name: 'categoryArticlesPage',
        path: '/category/:categoryName',
        props: true,
        component: () => import('@/views/CategoryArticlesPage.vue')
    },
    {
        name: 'articlePage',
        path: '/category/:categoryName/:articleId/',
        props: true,
        component: () => import('@/views/ArticlePage.vue')
    },
    {
        name: 'editOrder',
        path: '/category/:categoryName/:articleId/:orderId',
        props: true,
        component: () => import('@/views/ArticlePage.vue')
    },
    {
        name: 'suggestedArticlesPage',
        path: '/suggestedarticles/:categoryName/:articleId',
        props: true,
        component: () => import('@/views/SuggestedArticlesPage.vue')
    },
    {
        name: 'checkoutPage',
        path: '/checkout',
        component: () => import('@/views/CheckoutPage.vue')
    },
    {
        name: 'paymentPage',
        path: '/checkout/payment/:orderId',
        props: true,
        component: () => import('@/views/PaymentPage.vue')
    },
    {
        name: 'orderPage',
        path: '/order/:orderId',
        props: true,
        component: () => import('@/views/OrderPage.vue')
    },
    {
        name: 'ordersPage',
        path: '/orders',
        component: () => import('@/views/OrdersPage.vue')
    },
    {
        name:"termsAndConditions",
        path: '/terms-and-conditions',
        component: () => import('@/views/TermsAndConditions.vue')
    },
    {
        name:"privacyPolicy",
        path: '/privacy-policy',
        component: () => import('@/views/PrivacyPolicy.vue')
    },
    {
        name:"generateQR",
        path: '/generate-qr',
        component: () => import('@/views/GenerateQRCode.vue')
    }

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

})

export default router
