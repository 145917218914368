import { orderStoreKey } from '@/keys';
import { OrderDetails, OrderStatus, OrderStoreState, ResponseError, PaidOrder } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { OrderStatusState } from '@/enums';
import { isResponseError } from '../functions';


export const OrderStore = defineStore(orderStoreKey, () => {

    /* State */
    const state = ref<OrderStoreState>({
        details: null,
        paidOrders: [],
        deliveredOrders: []
    });

    const pullInterval = 600000; // 1 minute;
    let pullIntervalKey: number | undefined;

    const loadFromStorage = (e: StorageEvent | undefined | null) => {

        //If event was thrown by irrelevant storage
        if (e && e.key !== orderStoreKey) {
            return;
        }

        const orderJson: string | null = localStorage.getItem(orderStoreKey);

        if (orderJson) {
            try {
                state.value = JSON.parse(orderJson) as OrderStoreState;

                state.value.paidOrders.forEach(x => {
                    //If expires is a string, convert it to Date
                    if (typeof x.expires === 'string') {
                        x.expires = new Date(x.expires);
                    }
                })

                state.value.deliveredOrders.forEach(x => {
                    //If expires is a string, convert it to Date
                    if (typeof x.expires === 'string') {
                        x.expires = new Date(x.expires);
                    }
                })

            }
            catch (ex) {
                //If json is corrupt, remove data.
                localStorage.removeItem(orderStoreKey);
            }
        }
    }

    const id = computed<string | null>(() => {
        if (state.value?.details) {
            return state.value.details.orderId
        }
        return null;
    });

    const ordersCount = computed<number>(() => {
        return state.value.deliveredOrders.length + state.value.paidOrders.length;
    });

    const save = () => {
        localStorage.setItem(orderStoreKey, JSON.stringify(state.value));
    }

    const set = (item: OrderDetails) => {
        state.value.details = item;
        state.value.details.userAgent = window.navigator.userAgent;
        //Store items in local storage.
        localStorage.setItem(orderStoreKey, JSON.stringify(state.value));
    }

    const addPaymentID = (id: string | null) => {
        if (state.value.details && id) {
            state.value.details.paymentID = id;
        }
    }

    const clear = (clearStore: boolean | undefined) => {

        if (clearStore) {
            state.value.details = null;
            state.value.paidOrders = [];
            state.value.deliveredOrders = [];
        }

        clearInterval(pullIntervalKey);
        pullIntervalKey = undefined;
        localStorage.removeItem(orderStoreKey);
    }


    const cancelPullOrderStatus = () => {
        clearInterval(pullIntervalKey);
    }

    const pullOrderStatus = () => {
        if (!pullIntervalKey) {
            getOrderStatus();
            pullIntervalKey = setInterval(getOrderStatus, pullInterval);
        }
    };


    //Remove all done paid orders after they expire
    const removeExpiredOrders = () => {

        const now = Date.now();

        if (state.value.paidOrders.length) {
            state.value.paidOrders = state.value.paidOrders.filter(x => x.expires.getTime() > now);
        }

        if (state.value.deliveredOrders.length) {
            state.value.deliveredOrders = state.value.deliveredOrders.filter(x => x.expires.getTime() > now);
        }

        save()
    }

    const validateOrders = () => {
        if (state.value.paidOrders.length) {
            state.value.paidOrders = state.value.paidOrders.filter(x => x.customerCode !== null);
        }

        if (state.value.deliveredOrders.length) {
            state.value.deliveredOrders = state.value.deliveredOrders.filter(x => x.customerCode !== null);
        }

        save()
    }

    const addToPaidOrders = () => {
        if (state.value.details?.orderId && state.value.details.pickupTime) {
            if (!state.value.paidOrders.find(x => x.id === state.value.details?.orderId)) {

                //If pickupTime is a string, convert it to Date
                if (typeof state.value.details.pickupTime === 'string') {
                    state.value.details.pickupTime = new Date(state.value.details.pickupTime);
                }

                const expires = state.value.details.pickupTime;

                expires.setMinutes(expires.getMinutes() + 60) // Set expires 60 minutes in the future

                state.value.paidOrders.push({
                    id: state.value.details.orderId,
                    expires: expires,
                    customerCode: state.value.details.customerCode
                });
                save();
            }
        }
    }

    const removeOrderFromPending = () => {
        if (state.value.details?.orderId) {
            const index = state.value.paidOrders.findIndex(x => x.id === state.value.details?.orderId);
            if (index !== -1) {
                state.value.paidOrders.splice(index, 1);
                save();
            }
        }
    }

    /* AbortController */
    const abortController = new AbortController();
    const getOrderStatus = () => {

        validateOrders();
        removeExpiredOrders();


        if (state.value.paidOrders.length) {

            const order = state.value.paidOrders[0];

            if (order.customerCode) {

                fetch(`/api/order/${order.id}`, {
                    headers: {
                        'X-CustomerCode': order.customerCode
                    },
                    method: 'GET',
                    signal: abortController.signal
                })
                    .then(response => response.json())
                    .then((data: OrderStatus | ResponseError) => {

                        if (isResponseError(data)) {
                            console.error('Error -> getOrderStatus', data);
                        }
                        else {

                            if (data.status && typeof data.status === 'string') {

                                // state.value.status = data.status;

                                switch (data.status) {
                                    case OrderStatusState.Processing:
                                        // Do someting
                                        break;
                                    case OrderStatusState.Received:
                                        // Do someting
                                        break;
                                    case OrderStatusState.Started:
                                        // Do someting
                                        break;
                                    case OrderStatusState.Done:
                                        onDone(order);
                                        break;
                                    case OrderStatusState.Delivered:
                                        onDelivered(order);
                                        break;
                                }
                            }
                        }

                    }).catch((error) => {
                        console.error('error', error);
                    });
            } else {
                state.value.paidOrders.shift();//Remove order id customerCode is null
            }
        }
        else {
            cancelPullOrderStatus();
        }
    }

    const onDelivered = (order: PaidOrder) => {
        // Remove order from array.

        if (order) {
            const index = state.value.paidOrders.findIndex(x => x.id === order.id);
            state.value.paidOrders.splice(index, 1);

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 30) // Set expires 30 minutes in the future
            order.expires = expires;
            state.value.deliveredOrders.push(order);
        }

        if (!state.value.paidOrders.length) // If no order left then end status pulling
        {
            clearInterval(pullIntervalKey);
            pullIntervalKey = undefined;
        }
    }

    const onDone = (order: PaidOrder) => {
        if (order) {
            const index = state.value.paidOrders.findIndex(x => x.id === order.id);
            state.value.paidOrders.splice(index, 1);

            const expires = new Date();
            expires.setMinutes(expires.getMinutes() + 30) // Set expires 30 minutes in the future
            order.expires = expires;
            state.value.deliveredOrders.push(order);
        }

        if (!state.value.paidOrders.length) // If no order left then end status pulling
        {
            clearInterval(pullIntervalKey);
            pullIntervalKey = undefined;
        }
    }

    loadFromStorage(null);
    window.addEventListener('storage', loadFromStorage);

    return {
        save,
        clear,
        set,
        id,
        ordersCount,
        addPaymentID,
        state,
        getOrderStatus,
        pullOrderStatus,
        cancelPullOrderStatus,
        addToPaidOrders,
        removeOrderFromPending,
        removeExpiredOrders
    }

})