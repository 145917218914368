import { menuStoreKey } from '@/keys';
import { ChoiceLevel, Currency, Menu } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const MenuStore = defineStore(menuStoreKey, () => {
    const menu = ref<Menu>();
 
    const loadFromStorage = (e: StorageEvent | undefined | null) => {

        //If event was thrown by irrelevant storage
        if (e && e.key !== menuStoreKey) {
            return;
        }

        const json: string | null = localStorage.getItem(menuStoreKey);

        if (json) {

            try {
                menu.value = JSON.parse(json) as Menu;
            }
            catch (ex) {
                //If json is corrupt, remove data.
                clear();
            }
        } 
    }


    function set(item: Menu) {
        menu.value = item;
        //Store items in local storage.
        localStorage.setItem(menuStoreKey, JSON.stringify(menu.value));
    }

    const load = (shopId: number | null | undefined) => {
        return new Promise((resolve: any, reject: any) => {

            if (shopId) {
                fetch(`/api/shops/${shopId}/categories`)
                    .then((response) => response.json())
                    .then((data) => {
                        set(data);
                        resolve(data);
                    }).catch(err => {
                        reject(err);
                    });

            } else {
                reject(new Error("shopId is null or undefined"));
            }
        });
    }

    function clear() {
        localStorage.removeItem(menuStoreKey);
    }

    const getChoiceLevelName = (level: number) => {
        if (menu.value) {
            const choiceLvl = menu.value.choiceLevels.find((choiceLevel: ChoiceLevel) => choiceLevel.level === level);

            return choiceLvl ? choiceLvl.name : level.toString();
        }

        return level.toString();
    }

    const getCurency = (): Currency => {
        if (menu.value) {
            return menu.value.currency;
        }

        return { name: '', symbol: ':-' } as Currency;
    };


    loadFromStorage(null);
    window.addEventListener('storage', loadFromStorage);


    return {
        menu,
        set,
        clear,
        load,
        getChoiceLevelName,
        getCurency
    }
})