import { createI18n } from 'vue-i18n';
const messages = {
    en: {
        home: {
            header: 'Welcome!',
            subHeader:  'Choose your restaurant',
        },
        list: {
            menu: 'Menu'
        },
        shopchanged: {
            header: 'Warning!',
            text: 'You have a pending order linked to a different restaurant than the current one.'
        },
        checkout: {
            header: 'Checkout',
            pickup: 'Pickup time',
            orderHeader: 'My order',
        },
        payment: {
            header: 'Pay order'
        },
        termsAndConditions: {
            header: 'Terms and Conditions'
        },
        suggestedArticles: {
            header: 'May we offer something else?',
        },
        selectedShop: {
            header: 'Choose where to eat',
            extraInfo: ''
        },
        search: {
            label: 'Search restaurant',
            placeholder: 'Find restaurant',
            noMatch: 'No matching restaurants'
        },
        shopSelector: {
          singleShop: {
            start: "Start ordering",
            closed: "Closed for today",
            paused: "Closed for ordering",
           openingHoursHeader: "Opening hours",
          }
        },
        button: {
            showMore: 'Show more',
            showMoreRestaurants: 'Show more restaurants',
            open: 'Open',
            closed: 'Closed',
            paused: 'Weborders closed',
            dineInPlace: 'At the restaurant',
            takeAway: 'Take away',
            pay: 'Pay',
            order: 'Order',
            change: 'Change',
            noThanks: 'No thanks',
            done: 'Done',
            cancel: 'Cancel',
            changeRestaurant: 'Change restaurant',
            changeLanguage: 'Change language',
            cancelOrder: 'Cancel order',
            yes: 'Yes',
            no: 'No',
            add: 'Add',
            back: 'Back',
            receipt: 'Receipt'

        },
        item: {
            notAvalible: 'item out of stock',
        },
        label: {
            total: 'Total',
            subtotal: 'Subtotal',
            tax: 'Tax ({percent}%)',
            articleChoiceLabel1: 'Change/add ingredients',
            articleChoiceLabel2: 'Change',
            articleChoiceLabel3: 'Choose extra accessories',
            articleChoiceLabel4: 'Change',
            articleChoiceLabel5: 'Choose flavor',
            dineInPlace: 'Eat here',
            takeAway: 'Take away',
            now: 'Now'
        },
        order: {
            header: 'My order',
            pageHeader: 'Your order',
            thankYouText: 'Thanks for your order',
            infoText: '(Tap on product to change)',
            emptyInfoText: 'No items in the basket yet',
            selectedRestaurant: 'Selected restaurant',
            missing: 'Order is missing',
            pickUpLabel: 'Estimated pick up time',
            statusLabel: 'current status',
            numberLabel: 'Your order number',
            note: "Delivery to:",
            status: {
                processing: "Verifying order...",
                received: 'Your order is received',
                started: 'Your order is being prepared!',
                readyforpickup: 'Ready for pickup!',
                delivered: "Delivered!",
                rollbacked: "Order cancelled, the reservation on your card has been removed, if you have payed with Swish, please contact the restaurant for a refund."
            }
        },
        loader: {
            text: 'Loading...',
            languages: 'Looking for language options',
            categories: 'Looking for categories',
            image: '',
            calculatesDistances: 'Calculates distances',
        },
        cancel: {
            header: 'Cancel',
            text: 'Do you want to cancel?',
            order: {
                header: 'Cancel order',
                description: 'Do you want to cancel your order?',
                confirm: 'Yes, cancel',
                cancel: 'No, continue',
            }
        },
        error: {
            heading: 'Error message',
            server: 'There were a server error, please try again.',
            badRequest: 'Your browser sent a request that this server could not understand',
            unknown: 'Unknown error, please try again.',
            order: {
                "unavailable-products": "One or more of the products in your order is no longer available for sale.",
                "shop-closed": "The shop you are ordering from are no longer open, try again later.",
                "shop-closed-at-pickuptime": "The shop is closed at your specified pickup time",
                "shop-paused": "The shop you are ordering from are sadly not accepting any online orders right now, try again later.",
                "create-order-failed": "For some reason we could not create your order, please try again later.",
            },
            payment: {
                failed: 'An unknown error occured at payment (code:01)',
                aborted: 'An unknown error occured at payment (code:02)',
                notPaid: 'An unknown error occured at payment (code:03)',
                invalid: 'An unknown error occured at payment (code:04)',
                accesstokenMissing: 'An unknown error occured at payment (code:05)',
                IdentifierMissing: 'An unknown error occured at payment (code:06)',
                NotFound: 'An unknown error occured at payment (code:07)',
                merchantNotFound: 'An unknown error occured at payment (code:08)',
                orderNotFound: 'An unknown error occured at payment (code:09)',
                returnUrl: 'An unknown error occured at payment (code:10)',
                requestParametersMissing: 'An unknown error occured at payment (code:11)',
                orderFailed: 'An unknown error occured at payment (code:12)',
                captureFailed: 'An unknown error occured at payment (code:13)',
                createError: 'An unknown error occured at payment (code: 14)',
                loadScriptError: 'An unknown error occured at payment (kod: 15)',
            }
        },
        privacyPolicy: {
            header: 'Privacy policy'
        },
        receipt: {
            heading: "Receipt",
            total: "Total",
            vat: "VAT",
            net:"Net",
            orderNumber: "OrderNr",
            paymentId: "Payment id",
            note: "Delivery to",
            email: "Email:",
            emailPlaceHolder: "Enter your email address",
            sendEmail: "Send receipt",
            emailSent: "Email sent",
            deliveryDate:"Delivery time",
        },
        article :{
            allergens: 'Information about allergens go here with a link to <a href="#">somewhere</a>'
        },
        offline: {
            header: 'Offline',
            text: 'You are offline, please check your internet connection and try again.',
            button: 'Try again'
        }
    },
    sv: {
        home: {
            header: 'Välkommen!',
            subHeader:  'Välj din restaurang'
        },
        list: {
            menu: 'Meny'
        },
        shopchanged: {
            header: 'Varning!',
            text: 'Du har en pågående beställning kopplad till en annan restaurang än den nuvarande.'
        },
        checkout: {
            header: 'Kassa',
            pickup: 'När ska maten vara klar?',
            orderHeader: 'Min beställning',
        },
        payment: {
            header: 'Betala order'       
        },
        termsAndConditions: {
            header: 'Allmänna villkor'
        },
        suggestedArticles: {
            header: 'Får vi erbjuda något annat?',
        },
        selectedShop: {
            header: 'Välj var du vill äta',
            extraInfo: ''
        },
        search: {
            label: 'Sök restaurang',
            placeholder: 'Hitta restaurang',
            noMatch: 'Ingen matchande restaurang'
        },
        shopSelector: {
            singleShop: {
                start: "Beställ här",
                closed: "Stängt för idag",
                paused: "Stängt för beställning",
                openingHoursHeader: "Dagens öppettider på webben",
            }
        },
        button: {
            showMore: 'Visa mer',
            showMoreRestaurants: 'Visa fler restauranger',
            open: 'Öppet',
            closed: 'Stängt',
            paused: 'Stängt på webben',
            dineInPlace: 'På restaurangen',
            takeAway: 'Ta med',
            pay: 'Betala',
            order: 'Beställ',
            change: 'Ändra',
            noThanks: 'Nej tack',
            done: 'Klar',
            cancel: 'Avbryt',
            changeRestaurant: 'Byt restaurang',
            changeLanguage: 'Byt språk',
            cancelOrder: 'Avbryt order',
            yes: 'Ja',
            no: 'Nej',
            add: 'Lägg till',
            back: 'Tillbaka',
            receipt: 'Kvitto'
        },
        item: {
            notAvalible: 'Tillfälligt slut',
        },
        label: {
            total: 'Totalt',
            subtotal: 'Netto',
            tax: 'Varav moms ({percent}%)',
            dineInPlace: 'Äta på restaurangen',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Anpassa',
            articleChoiceLabel2: 'Byt tillbehör',
            articleChoiceLabel3: 'Lägg till extra tillbehör',
            articleChoiceLabel4: 'Ändra dryck',
            articleChoiceLabel5: 'Välj smak',
            now: 'Så snart som möjligt',
        },
        order: {
            header: 'Min order',
            pageHeader: 'Din beställning',
            thankYouText: 'Tack för din beställning',
            infoText: '(Tryck på produkt för att ändra)',
            emptyInfoText: 'Inga varor i korgen ännu',
            selectedRestaurant: 'Vald restaurang',
            missing: 'Order saknas',
            pickUpLabel: 'Beräknad hämtningstid',
            statusLabel: 'Nuvarande status',
            numberLabel: 'Ditt beställningsnummer',
            note: "Levereras till:",
            status: {
                processing: "Kontrollerar beställning...",
                received: 'Din beställning är mottagen.',
                started: 'Din beställning tillagas!',
                readyforpickup: 'Klar för avhämtning!',
                delivered: "Upphämtad, hoppas maten smakar!",
                rollbacked: "Order avbruten, reservationen på kortet är borttagen, har du betalat med Swish bör du kontakta restaurangen för återbetalning.",
            }
        },
        loader: {
            text: 'Laddar...',
            languages: 'Letar efter språkval',
            categories: 'Letar efter kategorier',
            image: '',
            calculatesDistances: 'Beräknar avstånd',
        },
        cancel: {
            header: 'Avbryt',
            text: 'Vill du verkligen avbryta?',
            order: {
                header: 'Avbryt order',
                description: 'Vill du avbryta din beställning?',
                confirm: 'Ja, avbryt',
                cancel: 'Nej, fortsätt',
            }
        },
        error: {
            heading: 'Felmeddelande',
            server: 'Det uppstod ett serverfel, försök igen.',
            badRequest: 'Din webbläsare skickade en begäran som den här servern inte kunde förstå.',
            unknown: 'Okänt fel, försök igen.',
            order: {
                "unavailable-products": "Någon av produkterna i din varukorg är inte längre tillgängliga för beställning",
                "shop-closed": "Butiken du försöker beställa till är stängd, försök igen senare.",
                "shop-closed-at-pickuptime": "Butiken du försöker beställa till är stängd vid den angivna upphämtningstiden",
                "shop-paused": "Butiken du försöker beställa till tar tyvärr inte emot beställningar via webben just nu, försök igen senare.",
                "create-order-failed": "Tyvärr gick det för närvarande inte att skapa din order, var god försök igen senare.",
            },
            payment: {
                failed: 'Det uppstod tyvärr ett fel vid betalningen (kod:01)',
                aborted: 'Det uppstod tyvärr ett fel vid betalningen (kod:02)',
                notPaid: 'Det uppstod tyvärr ett fel vid betalningen (kod:03)',
                invalid: 'Det uppstod tyvärr ett fel vid betalningen (kod:04)',
                accesstokenMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:05)',
                IdentifierMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:06)',
                NotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:07)',
                merchantNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:08)',
                orderNotFound: 'Det uppstod tyvärr ett fel vid betalningen (kod:09)',
                returnUrl: 'Det uppstod tyvärr ett fel vid betalningen (kod:10)',
                requestParametersMissing: 'Det uppstod tyvärr ett fel vid betalningen (kod:11)',
                orderFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:12)',
                captureFailed: 'Det uppstod tyvärr ett fel vid betalningen (kod:13)',
                createError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 14)',
                loadScriptError: 'Det uppstod tyvärr ett fel vid betalningen (kod: 15)',
            }
        },
        privacyPolicy: {
            header: 'Sekretess policy',
            policy: "                            <p>Vi på Winpos respekterar din integritet och har åtagit oss att skydda din personliga information. Denna integritetspolicy beskriver den information vi samlar in, hur vi använder den och hur vi skyddar den i samband med din användning av vår weborder/app.</p>\n" +
                "                            <h3>Information vi samlar in</h3>\n" +
                "                            <p>Vi kan samla in personuppgifter som du tillhandahåller när du använder vår weborder/app, till exempel din IP-adress. Vi kan också samla in icke-personlig information, till exempel din enhetstyp och användningsdata.</p>\n" +
                "                            <h3>Hur vi använder din information</h3>\n" +
                "                            <p>Vi använder den personliga information vi samlar in för att uppfylla din beställning, tillhandahålla kundsupport och kommunicera med dig om vår weborder/app. Vi kan också använda din information för att skicka dig marknadsföringskommunikation om våra andra produkter och tjänster.</p>\n" +
                "                            <p>Vi kan dela din information med tredje parts tjänsteleverantörer som hjälper oss att uppfylla din beställning och tillhandahålla kundsupport. Vi kan också dela din information med våra dotterbolag och partners för marknadsföringsändamål.</p>\n" +
                "                            <h3>Rättslig grund för behandling</h3>\n" +
                "                            <p>Enligt GDPR behandlar vi dina personuppgifter på den rättsliga grunden för ditt samtycke eller för att uppfylla ett avtal med dig. Vi har också ett berättigat intresse av att behandla din information för kundsupport och marknadsföringsändamål.</p>\n" +
                "                            <h3>Hur vi skyddar din information</h3>\n" +
                "                            <p>Vi vidtar rimliga åtgärder för att skydda din personliga information från obehörig åtkomst, avslöjande, ändring och förstörelse. Vi använder säkerhetsåtgärder av branschstandard, såsom kryptering och säkra servrar, för att skydda din information.</p>\n" +
                "                            <h3>Dina rättigheter</h3>\n" +
                "                            <p>Enligt GDPR har du rätt att få tillgång till, korrigera, radera och begränsa behandlingen av dina personuppgifter. Du har också rätt att invända mot behandlingen av dina personuppgifter för marknadsföringsändamål.</p>\n" +
                "                            <p>Om du vill utöva någon av dessa rättigheter, vänligen kontakta oss på info@winpos.se. Vi kommer att svara på din förfrågan inom 30 dagar.</p>\n" +
                "                            <h3>Uppdateringar av denna sekretesspolicy</h3>\n" +
                "                            <p>Vi kan uppdatera denna sekretesspolicy från tid till annan för att återspegla förändringar i vår praxis eller tillämplig lag. Vi kommer att meddela dig om eventuella väsentliga ändringar genom att publicera den uppdaterade sekretesspolicyn på vår webbplats eller via vår weborder/app.</p>\n" +
                "                            <h3>Kontakta oss</h3>\n" +
                "                            <p>Om du har några frågor eller funderingar om denna sekretesspolicy eller vår GDPR-efterlevnad, vänligen kontakta oss på info@winpos.se.</p>\n" +
                "                            <p>Ikraftträdandedatum: 2023-05-01</p>"
        },
        receipt: {
            heading: "Kvitto",
            total: "Totalt",
            vat: "Moms",
            net:"Netto",
            orderNumber: "OrderNr",
            paymentId: "Betalningsid",
            note: "Levereras till",
            email: "E-post:",
            emailPlaceHolder: "Skriv in din e-postadress",
            sendEmail: "Skicka kvitto",
            emailSent: "Kvitto skickat",
            deliveryDate: "Leveranstid",
        },
        article :{
            allergens: 'Information om allergener bör skrivas ut här, med en länk till <a href="#">någonstans</a>.'
        },
        offline: {
            header: 'Det går inte ansluta till internet',
            text: 'Kontrollera din internetanslutning och försök igen.',
            button: 'Försök igen'
        }
    },
    fi: {
        home: {
            header: 'Tervetuloa!',
            subHeader:  'Valitse ravintola',
        },
        list: {
            menu: 'Menu'
        },
        shopchanged: {
            header: 'Varoitus!',
            text: 'Sinulla on odottava tilaus toisessa ravintolassa kuin nyt valitussa ravintolassa.'
        },
        checkout: {
            header: 'Kassa',
            pickup: 'Noutoaika',
            orderHeader: 'Tilaukseni',
        },
        payment: {
            header: 'Maksa tilaus'
        },
        termsAndConditions: {
            header: 'Käyttöehdot'
        },
        suggestedArticles: {
            header: 'Voimmeko tarjota jotain muuta?',
        },
        selectedShop: {
            header: 'Valitse missä haluat syödä',
        },
        search: {
            label: 'Etsi ravintola',
            placeholder: 'Löydä ravintola',
            noMatch: 'Vastaavia ravintoloita ei löytynyt'
        },
        shopSelector: {
          singleShop: {
            start: "Aloita tilaus",
            closed: "Suljettu tältä päivältä",
            paused: "Suljettu tilauksilta",
           openingHoursHeader: "Aukioloajat",
          }
        },
        button: {
            showMore: 'Näytä enemmän',
            showMoreRestaurants: 'Näytä enemmän ravintoloita',
            open: 'Auki',
            closed: 'Suljettu',
            paused: 'Suljettu tilauksilta',
            dineInPlace: 'Syön ravintolassa',
            takeAway: 'Otan mukaan',
            pay: 'Maksa',
            order: 'Tilaa',
            change: 'Muokkaa',
            noThanks: 'Ei kiitos',
            done: 'Valmis',
            cancel: 'Peruuta',
            changeRestaurant: 'Vaihda ravintola',
            changeLanguage: 'Vaihda kieli',
            cancelOrder: 'Peru tilaus',
            yes: 'Kyllä',
            no: 'Ei',
            add: 'Lisää',
            back: 'Takaisin',
            receipt: 'Kuitti'

        },
        item: {
            notAvalible: 'tuote loppu',
        },
        label: {
            total: 'Yhteensä',
            subtotal: 'Välisumma',
            tax: 'Alv ({percent}%)',
            articleChoiceLabel1: 'Muokkaa/lisää',
            articleChoiceLabel2: 'Muokkaa',
            articleChoiceLabel3: 'Valitse lisukkeet',
            articleChoiceLabel4: 'Muokkaa',
            articleChoiceLabel5: 'Valitse maku',
            dineInPlace: 'Syön ravintolassa',
            takeAway: 'Otan mukaan',
            now: 'Nyt'
        },
        order: {
            header: 'Tilaukseni',
            pageHeader: 'Tilauksesi',
            thankYouText: 'Kiitos tilauksestasi',
            infoText: '(Napauta tuotetta tehdäksesi muutoksia)',
            emptyInfoText: 'Ei vielä tuotteita ostoskorissa',
            selectedRestaurant: 'Valittu ravintola',
            missing: 'Tilaus puuttuu',
            pickUpLabel: 'Arvioitu noutoaika',
            statusLabel: 'nykyinen tila',
            numberLabel: 'Tilausnumerosi',
            note: "Toimitus osoitteeseen:",
            status: {
                processing: "Vahvistetaan tilausta...",
                received: 'Tilauksesi on vastaanotettu',
                started: 'Tilaustasi valmistellaan!',
                readyforpickup: 'Valmis noudettavaksi!',
                delivered: "Toimitettu!",
                rollbacked: "Tilaus peruttu, korttisi varaus on poistettu. Jos olet maksanut Swishillä, ota yhteyttä ravintolaan rahan palautusta varten."
            }
        },
        loader: {
            text: 'Ladataan...',
            languages: 'Etsitään kielivaihtoehtoja',
            categories: 'Etsitään kategorioita',
            image: '',
            calculatesDistances: 'Lasketaan etäisyydet',
        },
        cancel: {
            header: 'Peruutus',
            text: 'Haluatko perua tilauksen?',
            order: {
                header: 'Peru tilaus',
                description: 'Haluatko perua tilauksesi?',
                confirm: 'Kyllä, perun tilauksen',
                cancel: 'Ei, jatka',
            }
        },
        error: {
            heading: 'Virheviesti',
            server: 'Palvelinvirhe, yritä uudelleen.',
            badRequest: 'Selaimesi lähetti pyynnön, jota tämä palvelin ei ymmärtänyt.',
            unknown: 'Tuntematon virhe, yritä uudelleen.',
            order: {
                "unavailable-products": "Yksi tai useampi tilauksesi tuotteista ei ole enää saatavilla.",
                "shop-closed": "Ravintola ei ole enää auki, yritä myöhemmin uudelleen.",
                "shop-closed-at-pickuptime": "Ravintola on suljettu valitsemanasi noutoaikana.",
                "shop-paused": "Ravintola ei valitettavasti ota vastaan verkkotilauksia juuri nyt, yritä myöhemmin uudelleen.",
                "create-order-failed": "Jostain syystä emme voineet luoda tilaustasi, yritä myöhemmin uudelleen.",
            },
            payment: {
                failed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:01)',
                aborted: 'Maksun yhteydessä tapahtui tuntematon virhe (code:02)',
                notPaid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:03)',
                invalid: 'Maksun yhteydessä tapahtui tuntematon virhe (code:04)',
                accesstokenMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:05)',
                IdentifierMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:06)',
                NotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:07)',
                merchantNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:08)',
                orderNotFound: 'Maksun yhteydessä tapahtui tuntematon virhe (code:09)',
                returnUrl: 'Maksun yhteydessä tapahtui tuntematon virhe (code:10)',
                requestParametersMissing: 'Maksun yhteydessä tapahtui tuntematon virhe (code:11)',
                orderFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:12)',
                captureFailed: 'Maksun yhteydessä tapahtui tuntematon virhe (code:13)',
                createError: 'Maksun yhteydessä tapahtui tuntematon virhe (code: 14)',
                loadScriptError: 'Maksun yhteydessä tapahtui tuntematon virhe (kod: 15)',
            }
        },
        privacyPolicy: {
            header: 'Tietosuojakäytäntö'
        },
        receipt: {
            heading: "Kuitti",
            total: "Yhteensä",
            vat: "Alv",
            net:"Netto",
            orderNumber: "TilausNro",
            paymentId: "Maksutunnus",
            note: "Toimitus osoitteeseen",
            email: "Sähköpostiosoite:",
            emailPlaceHolder: "Syötä sähköpostiosoitteesi",
            sendEmail: "Lähetä kuitti",
            emailSent: "Sähköposti lähetetty",
            deliveryDate:"Toimitusajankohta",
        },
        article :{
            allergens: 'Tietoa allergeeneista <a href="#">somewhere</a>'
        }
    },
    ru: {
        home: {
            header: 'Добро пожаловать! Выберите свой ресторан',
        },
        checkout: {
            header: 'Kassan',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
        },
        suggestedArticles: {
            header: 'Får vi erbjuda något annat?',
        },
        selectedShop: {
            header: 'Выберите, где поесть'
        },
        search: {
            label: 'Поиск ресторана',
            placeholder: 'Найти ресторан',
            noMatch: 'Нет подходящего ресторана'
        },
        button: {
            showMore: 'Visa mer',
            showMoreRestaurants: 'Показать больше ресторанов',
            open: 'открыть',
            closed: 'Закрыто',
            paused: 'Stängt på webben',
            dineInPlace: 'Есть здесь',
            takeAway: 'Приносить',
            pay: 'Betala',
            order: 'Beställ',
            change: 'Ändra',
            noThanks: 'Nej tack',
            done: 'Klar',
            cancel: 'Avbryt',
            changeRestaurant: 'Изменить ресторан',
            changeLanguage: 'переключить язык',
            cancelOrder: 'Avbryt order',
            yes: 'Да',
            no: 'Нет'
        },
        label: {
            total: 'Totalt',
            dineInPlace: 'Äta här',
            takeAway: 'Ta med',
            articleChoiceLabel1: 'Ändra/lägg till ingredienser',
            articleChoiceLabel2: 'Ändra',
            articleChoiceLabel3: 'Lägg till extra tillbehör',
            articleChoiceLabel4: 'Ändra',
            articleChoiceLabel5: 'Välj smak',
        },
        order: {
            header: 'Min order',
            infoText: '(Tryck på produkt för att ändra)',
            emptyInfoText: 'Inga varor i korgen ännu'
        },
        loader: {
            text: 'Letar efter hamburgare',
            languages: 'Letar efter språkval',
            categories: 'Letar efter kategorier',
            image: ''
        },
        cancel: {
            header: 'Avbryt',
            text: 'Vill du verkligen avbryta?',
            order: {
                header: 'Avbryt order',
                description: 'Vill du avbryta din beställning?',
                confirm: 'Ja, avbryt',
                cancel: 'Nej, fortsätt',
            }
        }
    }
}



export default createI18n({
    locale: 'sv',
    fallbackLocale: 'en',
    legacy: false,
    messages
})