import {IonicVue} from '@ionic/vue';
import mitt from 'mitt';
import {createPinia} from 'pinia';
import {createApp} from 'vue';
import App from './App.vue';
import i18n from "./i18n";
import router from './router';
import {messages} from 'i18n-customer-overrides';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
/* Theme variables */
import {AppStore} from '@/stores/app';
import {CartStore} from '@/stores/cart';
import {NavigationStore} from '@/stores/navigation';
import {ShopStore} from '@/stores/shop';
import {CouponStore} from "@/stores/coupon";
import './theme/style.scss';
import {OrderType} from "@/enums";
import {isOpen, isReachable} from "@/functions";

// import {registerWorker} from "@/functions/register-worker"; //needed for the future when we want to use push notifications
// registerWorker();
import './registerServiceWorker';

const handler = (e:Event)=>{
    const delay = 5000;
    if(navigator.onLine){
        setTimeout(()=>{
            isReachable(window.location.origin).then((online)=>{
                if(!online) {
                    console.log('offline');
                    handler(e);
                } else {
                    //if we are on the offline page, go to home, otherwise do nothing
                    router.currentRoute.value.name==='offline' && router.push({name:'home'});
                }
            });
        },delay);
    } else {
        //regardless of where we are, if we go offline, we go to the offline page
        router.push({name:'offline'});
    }
    console.log(e);
    console.log(navigator.onLine);
};

window.addEventListener("online", handler);
window.addEventListener("offline", handler);

const emitter = mitt();
const pinia = createPinia();

//Add customer specific overrides of i18n texts
i18n.global.mergeLocaleMessage('en', messages.en);
i18n.global.mergeLocaleMessage('sv', messages.sv);
i18n.global.mergeLocaleMessage('fi', messages.fi);
i18n.global.mergeLocaleMessage('ru', messages.ru);
i18n.global.mergeLocaleMessage('no', messages.no);


const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(pinia)
    .use(i18n);

router.isReady().then(() => {
    app.config.globalProperties.emitter = emitter;

    app.mount('#app');

    app.config.errorHandler = (err, instance, info) => {
        console.error('Global Error', err, instance, info);
    }
});

const navStore = NavigationStore();
const shopStore = ShopStore();
const cartStore = CartStore();
const appStore = AppStore();
const couponStore = CouponStore();
couponStore.load();

const cartHiddenPages = ['home', 'checkoutPage', 'shopPage', 'paymentPage', 'orderPage', 'ordersPage', 'termsAndConditions', 'privacyPolicy', 'offline', 'coupons'];
const cartShowAddItemButtonPages = ['editOrder', 'articlePage', 'suggestedArticlesPage'];
const cartDisabledPages = ['editOrder', 'articlePage', 'suggestedArticlesPage', 'shopChangedPage'];
const cartEditModePages = ['editOrder', 'suggestedArticlesPage']


router.beforeEach(async (to, from) => {
    //debugger;
    appStore.state.currentPage = to?.name;
    appStore.state.lastPage = from?.name;
    appStore.state.currentPageFullPath = to?.fullPath;
    appStore.state.lastPageFullPath = from?.fullPath;

    if (to?.name === 'privacyPolicy' || to?.name === 'generateQR' || to?.name==='offline') {
        navStore.state.transparent = false;
        return;
    }
    
    if(to?.name === 'coupons'){
        navStore.state.visible = true;
        navStore.state.transparent = false;
        navStore.state.link = { name: 'home', params: {} };
        cartStore.state.visible = false;
        return;
    }

    if (to?.name === 'categoriesPage') {
        //Support deep linking via QR codes to a specific shop, with a note and a on orderType
        const params = new URLSearchParams(window.location.search);
        const note = params.get('note');
        if (to.params.shopId) {
            if (note) {
                const shopId = Number(to.params.shopId);
                await shopStore.load(shopId);
                if (shopStore.shop && !isOpen(shopStore.shop)) {
                    return {name: 'home'}
                }

                cartStore.setNote(note);
                params.delete('note')

                const orderType = params.get('orderType');
                if (orderType) {
                    cartStore.setOrderType(orderType as OrderType);
                    params.delete('orderType')
                }
            }
        }
    }
    if(to?.name === 'shopPage' && to.params.shopId){
        //debugger;
        const shopId = Number(to.params.shopId);
        await shopStore.load(shopId);
        if (shopStore.shop && !isOpen(shopStore.shop)) {
            return {name: 'home'}
        }
    }

    // Go to Home if shopStore.id is missing.
    if (!shopStore.id && to.name !== 'home') {
        //console.log(to.fullPath);
        return {name: 'home'}
    }

    // Hide nav on Home
    if (to.name === 'home' || to?.name==='offline') {
        navStore.state.transparent = true;
        navStore.state.link = undefined;
        document.body.className = "";
    } else {
        navStore.state.transparent = false;
        if (cartStore.state.shopId) {
            document.body.className = `shop-${cartStore.state.shopId ?? 'none'}`;
        }
    }


    if (to.name !== 'home') {
        //If restaurant changed and user has items in cart then clear cart.
        if (cartStore.state.items.length > 0 && shopStore.id && !cartStore.compareShop(shopStore.id)) {
            cartStore.clear(true);
        }
    }

    cartStore.state.visible = typeof to.name === 'string' && !cartHiddenPages.includes(to.name.toString());
    //(to.name === 'home' || to.name === 'checkoutPage' || to.name == 'shopPage'
    //    || to.name === 'paymentPage' || to.name === 'orderPage');

    cartStore.state.showAddItemButton = typeof to.name === 'string' && cartShowAddItemButtonPages.includes(to.name);
    /*    (to.name === 'editOrder' || to.name === 'articlePage' || to.name === 'suggestedArticlesPage');*/

    cartStore.state.editMode = typeof to.name === 'string' && cartEditModePages.includes(to.name);


    if (typeof to.name === 'string' && cartDisabledPages.includes(to.name)) {
        //to.name === 'editOrder' || to.name === 'articlePage' || to.name === 'suggestedArticlesPage' || to.name === 'shopChangedPage'
        //Disable cart on EditOrder, articlePage and suggestedArticlesPage
        cartStore.state.disabled = true;

        // Hide order info on small screens       
        if (appStore.state.isSmallScreen) {
            cartStore.state.showOrderInfo = false;
        }

    } else {
        cartStore.state.disabled = false;
    }

    emitter.emit('onNavigate');
})

