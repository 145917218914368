import { shopStorageKey } from '@/keys';
import { Shop } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';




export const ShopStore = defineStore(shopStorageKey, () => {
    const shop = ref<Shop>();
 
   
    const loadFromStorage = (e: StorageEvent | undefined | null) => {

        //If event was thrown by irrelevant storage
        if (e && e.key !== shopStorageKey) {
            return;
        }
        const json: string | null = localStorage.getItem(shopStorageKey);
        if (json) {

            try {
                shop.value = JSON.parse(json) as Shop;
            }
            catch (ex) {
                //If json is corrupt, remove data.
                clear();
            }
        }
    }



    const id = computed<number | null>(() => {
        if (shop.value) {
            return shop.value.id
        }
        return null;
    });

    function set(item: Shop) {
        shop.value = item;
        //Store items in local storage.
        localStorage.setItem(shopStorageKey, JSON.stringify(shop.value));
    }

    const load = (shopId: number) => {
        return new Promise((resolve: any, reject: any) => {
            fetch(`/api/shops/${shopId}`)
                .then((response) => response.json())
                .then((data) => {
                    set(data);
                    resolve(data);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    function clear() {
        shop.value = undefined;
        localStorage.removeItem(shopStorageKey);
    }  

    loadFromStorage(null);
    window.addEventListener('storage', loadFromStorage);

    return {
        set,
        clear,
        shop,
        load,
        id
    }
})