
export enum CartItemType {
    Article,
    Suggested
}

export enum OrderType {
    TakeAway = "takeAway",
    DineInPlace = "dineInPlace"
}


export enum ScreenType {
    Large = 'large',
    Small = 'small'
}

export enum OrderStatusState {
    
    Processing = 'Processing',
    Received = 'Received',
    Started = 'Started',
    ReadyForPickup = 'ReadyForPickup',
    Done = 'Done',
    Delivered = 'Delivered',
    Rollbacked = 'Rollbacked',
}