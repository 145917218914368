import {ResponseError, Shop, Translation, Translations} from '@/types';

//returns id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
export const guid = () => {
    const s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
 
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

//Ex: statusCode: 500, prefix: "error." => "error.server"; 
export const errorToi18nKey = (statusCode: number, prefix = "error.") : string => {

    let message = 'unknown';

    if (statusCode >= 500) {
        message = 'server';
    }
    else if (statusCode >= 400) {
        message = 'badRequest';
    }
    else if (statusCode >= 300) {
        message = 'moved';
    }

    return `${prefix}${message}`;
}


export function isResponseError (obj: any): obj is ResponseError {
    return 'type' in obj && 'title' in obj && 'status' in obj;
}

export function isOpen(shop:Shop):boolean {
    if(shop.externalAppUri)
        return true;
    
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const now = new Date();

    const todayOpeningHours = shop.openingHours.find(o=>o.dayOfWeek == weekday[now.getDay()]);

    if(shop.status === 'offline' || shop.status === 'paused' || todayOpeningHours && todayOpeningHours.closed || !todayOpeningHours)
        return false;

    if(!todayOpeningHours.opens || !todayOpeningHours.closes)
        return false;

    const opens =new Date(todayOpeningHours.opens);
    opens.setFullYear(now.getFullYear(),now.getMonth(),now.getDate())

    const closes =new Date(todayOpeningHours.closes);
    closes.setFullYear(now.getFullYear(),now.getMonth(),now.getDate())

    return now > opens && now < closes;
    // const weekDayOpeningHours = shop.openingHours.find(x=>x.dayOfWeek == date.toLocaleString('en-us', {  weekday: 'long' }));
    // if(!weekDayOpeningHours || weekDayOpeningHours.closed || shop.status == "offline"){
    //     return false;
    // }
    //
    // const opens = new Date(Date.parse(weekDayOpeningHours.opens));
    // const closes = new Date(Date.parse(weekDayOpeningHours.closes));
    //
    // return date > opens && date < closes;
}

export function i18nTranslate(i18n:Translations, property:string, locale:string):string {
    if(!i18n)
        return "";
    const translations = i18n[property] as Translation[];
    const text = translations.find(t => t.language === locale)?.text;

    return text || "";
}

export function isReachable(url:string):Promise<boolean | void>{
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function(resp) {
            return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function(err) {
            console.warn('[conn test failure]:', err);
        });
}